import BellIcon from "../assets/images/icons/ic-bell.svg";

export const VERSION_2_UPDATE_DATE = "2023-07-03";
export const UPDATE_POPUP_DUE_DATE = "2023-08-31";
export const EXPIRE_TRAIL_INFO_DATE = "2024-07-12";
export const EXPIRE_TRAIL_DUE_DATE = "2024-08-13";

export const FAQ_V2_UPGRADE_URL =
    "https://innerviewbanner.notion.site/799fc4df85374f2498487bb6bf68768f?v=e3ac0826e7cd4239b582a269e4a5d577&p=39c80c78123b4cf392a321781f355d00&pm=s";
export const FAQ_URL =
    "https://innerviewbanner.notion.site/799fc4df85374f2498487bb6bf68768f?v=e3ac0826e7cd4239b582a269e4a5d577";
export const CHANGE_PAYMENT_URL =
    "https://innerviewbanner.notion.site/e4bb420d169e4392b281cc6dcd1328dc";
export const EXPIRE_TRAIL_INFO_URL =
    "https://www.notion.so/innerviewbanner/3f946b34633a4539b647fadfe934a637";
export const INNERVIEW_BANNER_APPSTORE_LINK = `https://store.cafe24.com/kr/apps/12159`;
export const EXPOSURE_ORDERS = {
    IN_ORDER: "default",
    RANDOM: "random",
};

export const BANNER_SORT_TYPES = {
    SLIDE: "slide",
    CUSTOM: "custom",
    FADE: "fade",
};

export const BANNER_SORT_DISPLAY = {
    slide: "Slider",
    fade: "Fade in/out",
    custom: "Custom",
};

export const FILE_TYPES = {
    PNG: "image/png",
    JPG: "image/jpeg",
    GIF: "image/gif",
    SVG: "image/svg+xml",
    JSON: "application/json",
};

export const IMAGE_MODAL_TAB_NAMES = {
    MANUAL: "manual",
    URL: "url",
    VIDEO: "video",
};

export const CONFIRM_TEXT = {
    FOLDER_REMOVE_CONFIRM_TEXT: `폴더 내에 등록된 모든 배너 그룹과 이미지가 영구 삭제됩니다.\n정말 폴더를 삭제하시겠습니까?`,
    BANNER_GROUP_REMOVE_CONFIRM_TEXT: `배너 그룹 내 모든 이미지가 영구 삭제됩니다.\n정말 배너 그룹을 삭제하시겠습니까?`,
    IMAGE_REMOVE_CONFIRM_TEXT: `등록된 이미지가 삭제됩니다.\n정말 삭제하시겠습니까?`,
    ESCAPE_CONFIRM_TEXT: `작성 중인 내용이 있습니다. 페이지를 벗어나시겠습니까?\n작성된 내용은 저장되지 않습니다.`,
};
export const TOAST_PROPS = {
    icon: () => <img src={BellIcon} alt="bell icon" />,
    position: "bottom-center",
    autoClose: 2000,
    hideProgressBar: true,
    // closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
};

export const MODAL_TYPES = {
    SUBSCRIBE: "subscribe",
    SCRIPT_INSTALL: "scriptInstall",
    BANNER_RESTORE: "bannerRestore",
    ADD_FOLDER: "addFolder",
    FOLDER_CONFIRM: "folderConfirm",
    BANNER_CODE: "bannerCode",
    CATEGORY_CONNECT: "categoryConnect",
    PRODUCT_SEARCH: "productSearch",
    IMAGE_MANAGE: "imageManage",
    USER_PLAN_INFO: "userPlanInfo",
    WARNING: "warning",
    EXPIRE_TRAIL_INFO: "expireTrailInfo",
    EXPIRE_TRAIL_WARN: "expireTrailWarning",
    TUTORIAL: {
        BANNER_GROUP: "bannerGroupTutorial",
        BANNER_CONFIG: "bannerConfigTutorial",
        CATEGORY_CONNECT: "categoryConnectTutorial",
        PRODUCT_CONNECT: "productConnectTutorial",
        IMAGE_MANAGE: "imageManageTutorial",
    },
};

export const MODAL_PAYLOAD = {
    TUTORIAL: {
        IMAGE_MANAGE: {
            modalType: MODAL_TYPES.TUTORIAL.IMAGE_MANAGE,
            modalProps: {
                title: "이미지 관리 사용법",
                width: 1400,
            },
        },
        PRODUCT_CONNECT: {
            modalType: MODAL_TYPES.TUTORIAL.PRODUCT_CONNECT,
            modalProps: {
                title: "상품 연결 사용법",
                width: 1400,
            },
        },
        CATEGORY_CONNECT: {
            modalType: MODAL_TYPES.TUTORIAL.CATEGORY_CONNECT,
            modalProps: {
                title: "카테고리 연결 사용법",
                width: 1400,
            },
        },
        BANNER_CONFIG: {
            modalType: MODAL_TYPES.TUTORIAL.BANNER_CONFIG,
            modalProps: {
                title: "배너 설정 사용법",
                width: 1400,
            },
        },
        BANNER_GROUP: {
            modalType: MODAL_TYPES.TUTORIAL.BANNER_GROUP,
            modalProps: {
                title: "배너 그룹 사용법",
                width: 1400,
            },
        },
    },
    USER_PLAN_INFO: {
        modalType: MODAL_TYPES.USER_PLAN_INFO,
        modalProps: {
            title: "innerviewit님의 결제 정보 안내",
            width: 1000,
        },
    },
    IMAGE_MANAGE: {
        modalType: MODAL_TYPES.IMAGE_MANAGE,
        modalProps: {
            title: "이미지 등록하기",
            width: 1000,
        },
    },
    PRODUCT_SEARCH: {
        modalType: MODAL_TYPES.PRODUCT_SEARCH,
        modalProps: {
            title: "상품 연결하기",
            width: 1000,
        },
    },
    CATEGORY_CONNECT: {
        modalType: MODAL_TYPES.CATEGORY_CONNECT,
        modalProps: {
            title: "카테고리 연결하기",
            width: 640,
        },
    },
    BANNER_CODE: {
        modalType: MODAL_TYPES.BANNER_CODE,
        modalProps: {
            title: "배너 코드 복사하기",
            width: 640,
        },
    },
    FOLDER_CONFIRM: {
        modalType: MODAL_TYPES.FOLDER_CONFIRM,
        modalProps: {
            width: 480,
        },
    },
    SUBSCRIBE: {
        modalType: MODAL_TYPES.SUBSCRIBE,
        modalProps: {
            width: 480,
        },
    },
    SCRIPT_INSTALL: {
        modalType: MODAL_TYPES.SCRIPT_INSTALL,
        modalProps: {
            title: "스크립트 설치",
            width: 640,
        },
    },
    BANNER_RESTORE: {
        modalType: MODAL_TYPES.BANNER_RESTORE,
        modalProps: {
            title: "배너 복원",
            width: 640,
        },
    },
    ADD_FOLDER: {
        modalType: MODAL_TYPES.ADD_FOLDER,
        modalProps: {
            title: "폴더 추가",
            width: 640,
        },
    },
    UPDATE_FOLDER_NAME: {
        modalType: MODAL_TYPES.ADD_FOLDER,
        modalProps: {
            title: "폴더명 변경",
            width: 640,
            isUpdate: true,
        },
    },
    WARNING: {
        modalType: MODAL_TYPES.WARNING,
        modalProps: {
            width: 480,
        },
    },
    EXPIRE_TRAIL_INFO: {
        modalType: MODAL_TYPES.EXPIRE_TRAIL_INFO,
        modalProps: {
            width: 515,
        },
    },
    EXPIRE_TRAIL_WARN: {
        modalType: MODAL_TYPES.EXPIRE_TRAIL_WARN,
        modalProps: {
            width: 480,
            modalPrevented: true,
        },
    },
};

export const DEFAULT_CUSTOM_CODE = `
<!-- 슬라이드들을 감싸는 '-ivBanner' class 명들은 고정-->
<!-- 이미지, 비디오가 들어가는 슬라이드의 class 명은 slide-ivBanner, image-slide-ivBanner/video-slide-ivBanner 고정 -->
<!-- ${"변수"} 형태로 되어있는 변수형식 고정 -->
<div class="container-ivBanner">
    <div class="wrapper-ivBanner">
      <div class="slide-ivBanner image-slide-ivBanner" data-title="\${title}" data-desc="\${desc}">
        <div onclick="clickCount(\${imageId}, '\${link}', '\${target}')">
        <img src="\${imageUrl}?date=\${uniqueCode}"/>
        <p>\${banner_text_01}</p>
        <p>\${banner_text_02}</p>
        <p>\${banner_text_03}</p>
        </div>
      </div>
      <div class="slide-ivBanner image-slide-ivBanner" data-title="\${title}" data-desc="\${desc}">
        <div onclick="clickCount(\${imageId}, '\${link}', '\${target}')">
        <img src="\${imageUrl}?date=\${uniqueCode}"/>
        <p>\${banner_text_01}</p>
        <p>\${banner_text_02}</p>
        <p>\${banner_text_03}</p>
        </div>
      </div>
      <div class="slide-ivBanner video-slide-ivBanner">
        <iframe src="\${videoUrl}"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen>
          </iframe>
      </div>
    </div>
  </div>
`
    .toString()
    .trim();
